* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  color: #f2f2f2;;
  padding: 100px 0;
}
